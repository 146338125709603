import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System;
using System.Collections.Generic;

// Classes
public class Candy
{
    public string Name { get; set; }
    public double Price { get; set; }

    public Candy(string name, double price)
    {
        Name = name;
        Price = price;
    }
}

// Entry point
public class Program
{
    public static void Main()
    {
        // Variables and data types
        string candyShopName = "Sweet Tooth";
        bool isOpen = true;
        string[] candyNames = { "chocolate", "gummy bears", "lollipops" };
        double[] candyPrices = { 2.50, 1.50, 0.50 };
        List<Candy> inventory = new List<Candy>();

        // Conditional statements
        if (isOpen)
        {
            Console.WriteLine(candyShopName + " is open!");
        }
        else
        {
            Console.WriteLine(candyShopName + " is closed.");
        }

        // Loops
        for (int i = 0; i < candyNames.Length; i++)
        {
            Candy candy = new Candy(candyNames[i], candyPrices[i]);
            inventory.Add(candy);
        }

        // Functions
        Func<List<Candy>, double> calculateTotalPrice = (candies) =>
        {
            double totalPrice = 0;

            foreach (Candy candy in candies)
            {
                totalPrice += candy.Price;
            }

            return totalPrice;
        };

        // Throwing exceptions
        Func<string, string> buyCandy = (candyName) =>
        {
            for (int i = 0; i < inventory.Count; i++)
            {
                if (inventory[i].Name == candyName)
                {
                    inventory.RemoveAt(i);
                    return "You bought a " + candyName + " for " + inventory[i].Price.ToString("0.00") + " dollars.";
                }
            }

            throw new Exception("Sorry, we are out of " + candyName + "s.");
        };

        // Using functions
        double total_price = calculateTotalPrice(inventory);
        Console.WriteLine("The total price of the candy in the inventory is " + total_price.ToString("0.00") + " dollars.");

        // Exception handling
        try
        {
            Console.WriteLine(buyCandy("gummy bears"));
        }
        catch (Exception ex)
        {
            Console.WriteLine("Error: " + ex.Message);
        }
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      